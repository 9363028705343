import banner from "../assets/img/banner-1.jpg";
const About = () => {
  return (
    <div className="about-section">
      <img src={banner} className="banner" alt="banner"></img>
      <h1>KLAUZULA INFORMACYJNA DOTYCZĄCA DANYCH OSOBOWYCH</h1>
      <div className="text-box">
        <p>
          Przedsiębiorstwo budowlane PM-INWEST Sp. z.o.o. z siedzibą w
          Szczecinie Na podstawie art. 13 ust. 1 i ust. 2 oraz art. 14
          rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27
          kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO),
          informuje o zasadach przetwarzania Pani/Pana danych osobowych oraz o
          przysługujących prawach z tym związanych. Powyższe zasady stosuje się
          począwszy od 25 maja 2018 roku
        </p>
        <br></br>
        <table>
          <tbody>
            <tr>
              <td>1</td>
              <td>Administrator Danych Osobowych</td>
              <td>PM-INWEST Sp. z.o.o. KRS 0000929744, NIP 9552559945, </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Dane kontaktowe Administratora</td>
              <td>
                adres do kontaktu: ul. Spiżowa 11A, 70-723 Szczecin email do
                kontaktu: biuropminwest@gmail.com{" "}
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Dane kontaktowe przedstawiciela Administratora</td>
              <td>Piotr Marecki pminwest@wp.pl</td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Cele przetwarzania danych i podstawa prawna przetwarzania dany
              </td>
              <td>
                Dane osobowe będą przetwarzane w celach: 1. Zawarcia, wykonania
                i realizacji łączącej i/lub łączących nas umów dotyczących
                stosunku pracy i związanych ze stosunkiem pracy, a także w celu
                podjęcia wszelkich działań na żądanie osób, których dane dotyczą
                przed zawarciem umowy. Wśród tych celów dane osobowe Pracownika
                mogą być wykorzystywane we wszelkich czynnościach wynikających z
                zakresu obowiązków pracowniczych podczas świadczenia przez
                Administratora usług i robót na rzecz jego kontrahentów (np.
                kontakt z kontrahentami Administratora, podpisywanie faktur,
                protokołów, raportów, umów, pism, korespondencja e-mailowa itp.)
                Podstawa prawna: art. 6 ust. 1 pkt. b) RODO. 2. Wypełnienia
                obowiązków prawnych przez Administratora, w tym w szczególności
                w związku: a. wypełnianiem obowiązków i uprawnień wynikających
                ze stosunku pracy i przepisów prawa pracy, b. wypełnianiem
                obowiązków i uprawnień związanych z ubezpieczeniami społecznymi
                i zdrowotnymi i obowiązkami podatkowymi, c. wypełnianiem
                obowiązków i uprawnień wynikających z zasad i procedur
                bezpieczeństwa i higieny pracy, d. wypełnianiem obowiązków wobec
                władz, instytucji, organów, urzędów Sądów, Policji, Prokuratury,
                komorników na ich żądanie na podstawie wskazanej przez nich
                podstawy prawnej. Podstawa prawna: art. 6 ust. 1 pkt. c) RODO.
                3. Ewentualnych sporów sądowych, w tym ustalenia, dochodzenia
                lub obrony przed roszczeniami, co jest prawnie uzasadnionym
                interesem Administratora. Podstawa prawna: art. 6 ust. 1 pkt. f)
                RODO
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Kategorie danych, które są przetwarzane</td>
              <td>
                Imię, nazwisko, adres zameldowania, adres zamieszkania, adres
                korespondencyjny, numer telefonu, email, PESEL, numer dowodu
                osobistego lub innego dokumentu potwierdzającego tożsamość,
                rachunek bankowy i podmiot prowadzący rachunek bankowy, stan
                cywilny, członkowie rodziny, numer obuwia roboczego i rozmiar
                ubrania roboczego.
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Źródło pochodzenia danych osobowe</td>
              <td>Bezpośrednio od osoby, której dane dotyczą.</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Kategorie odbiorców danych</td>
              <td>
                Dane osobowe Pana/Pani mogą być przekazane: a. podmiotom
                uprawnionym na podstawie przepisów prawa (w tym w szczególności
                do organów ubezpieczeniowych, organów podatkowych, organów
                kontrolujących pracę i przepisy, organów urlopowych), b.
                podmiotom, z których usług Administrator korzysta przy
                wykonywaniu obowiązków, w tym w szczególności: podmiotom
                prowadzącym obsługę informatyczną, podmiotom prowadzącym obsługę
                księgową, podmiotom świadczącym pomoc prawną i usługi doradcze,
                podmiotom prowadzącym działalność pocztową lub kurierską,
                podmiotom prowadzącym działalność płatniczą (banki, instytucje
                płatnicze realizujące wypłatę wynagrodzeń), podmiotom
                prowadzącym obsługę bhp., c. kontrahentom Administratora zarówno
                na terenie Rzeczpospolitej Polskiej jak i na terenie Niemiec
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Przekazywanie danych poza Europejski Obszar Gospodarczy</td>
              <td>
                Dane osobowe nie będą przekazywane do podmiotów mających
                siedzibę poza Europejskim Obszarem Gospodarczym
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                Okres przechowywania danych (okres, przez który dane osobowe
                będę przechowywane, a gdy to nie jest możliwe, kryteria
                ustalenia tego okresu)
              </td>
              <td>
                Pani/Pana dane osobowe będą przetwarzany przez okres niezbędny
                do realizacji wskazanych w pkt. 2 powyżej celów przetwarzania
                tj.: 1. W zakresie realizacji zawartej umowy przez Panią/Pana
                umowy, przez okres do czasu zakończenia jej obowiązywania, a po
                tym czasie przez okres w zakresie określonym przez przepisy
                prawa do czasu przedawnienia wszystkich roszczeń wynikających z
                tej umowy oraz do upływu okresu czasu wymaganego w zakresie
                przechowywania tych dokumentów przez pracodawcę, którym jest
                Administrator. 2. W zakresie wykonania obowiązków prawnych przez
                okres przewidziany przepisami prawa, w szczególności z
                uwzględnieniem okresów przedawnienia w tych przypadkach, gdy
                dochodzi do przedawnienia oraz przez czas w którym przepisy
                nakazują przechowywać wszelkie dane. 3. W zakresie sporów
                sądowych przez okres 10 lat od dnia wydania prawomocnego
                orzeczenia kończącego postępowanie i wyegzekwowania należności.
                Pani/Pana dane osobowe będą przetwarzany przez okres niezbędny
                do realizacji wskazanych w pkt. 2 powyżej celów przetwarzania
                tj.: 1. W zakresie realizacji zawartej umowy przez Panią/Pana
                umowy, przez okres do czasu zakończenia jej obowiązywania, a po
                tym czasie przez okres w zakresie określonym przez przepisy
                prawa do czasu przedawnienia wszystkich roszczeń wynikających z
                tej umowy. 2. W zakresie wykonania obowiązków prawnych przez
                okres przewidziany przepisami prawa, w szczególności z
                uwzględnieniem okresów przedawnienia w tych przypadkach, gdy
                dochodzi do przedawnienia. 3. W zakresie dochodzenia należności
                przez okres 10 lat od zakończenia realizacji umowy. 4. W
                zakresie sporów sądowych i zabezpieczenia dowodów przez okres 10
                lat od dnia wydania prawomocnego orzeczenia kończącego
                postępowanie i wyegzekwowania należności. 5. W zakresie
                marketingu i promocji oferowanych lokali w przedsięwzięciach
                deweloperskich przez okres 10 lat od daty wyrażenia zgody, lecz
                nie dłużej niż do czasu wycofania takiej zgody.
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Prawa dotyczące danych osobowych</td>
              <td>
                Na każdym etapie przetwarzania danych osobowych przysługuje
                Pani/Panu prawo dostępu do swoich danych (art. 15 RODO), do
                sprostowania danych (art. 16 RODO) i usunięcia swoich danych
                (art. 17 RODO) jeżeli nie zachodzą nadal okoliczności o których
                mowa w art. 6 ust. 1 pkt. a), b), c) i f) RODO, prawo do
                ograniczenia przetwarzania (art. 18 RODO), bycia powiadomionym
                przez administratora danych o sprostowaniu, usunięciu lub o
                ograniczeniu przetwarzania danych (art. 19 RODO), prawo do
                wniesienia skargi do organu nadzorczego (art. 77 RODO), jeżeli
                uważa, że Administrator przetwarza dane niezgodnie z prawem (tj.
                może złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony
                Danych Osobowych).
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                Informacja o wymogu lub dobrowolności podania danych i
                konsekwencje ich nie podania
              </td>
              <td>
                Podanie danych osobowych w celu zawarcia umowy jest dobrowolne,
                lecz nie podanie tych danych uniemożliwi zawarcie umowy i jej
                realizację. W zakresie wypełnienia obowiązków prawnych przez
                Administratora podanie danych jest wymogiem ustawowym.
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                Przetwarzanie zautomatyzowane i profilowanie Pani/Pana dane nie
                będą przetwarzane w sposób zautomatyzowany, w tym również w
                formie profilowania.
              </td>
              <td>
                Pani/Pana dane nie będą przetwarzane w sposób zautomatyzowany, w
                tym również w formie profilowania.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default About;
