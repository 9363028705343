import logo from "../assets/img/logo.png";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import cogoToast from "cogo-toast";

const Footer = () => {
  const form = useRef();
  const onReset = () => {
    document.getElementById("one-form").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_djsvndy",
        "template_45xgi08",
        form.current,
        "ve-vvA2lTUgYFRQ95"
      )
      .then(
        (result) => {
          console.log(result);
          cogoToast.success("Mail został wysłany");
          onReset();
          // show the user a success message
        },
        (error) => {
          console.error(error);
          cogoToast.error("Coś jest nie tak");
          // show the user an error
        }
      );
  };
  return (
    <>
      <div className="footer">
        <div className="footer-box">
          <div className="footer-section">
            <div className="footer-left-section">
              <div className="footer-left-logo">
                <img className="logo-footer" src={logo} alt="logo"></img>
              </div>
              <div className="footer-left-link">
                <div className="footer-left-box">
                  <ul className="footer-contact">
                    <li>PMINWEST SP. Z O.O</li>
                    <li>SPIŻOWA 11A 70-723 SZCZECIN</li>
                    <li>NIP: 9552559945</li>
                    <li>NUMER TELEFONU: 606686818</li>
                    <li>PIERWSZY EMAIL: biuropminwest@wp.pl</li>
                    <li>DRUGI EMAIL: pminwest@wp.pl</li>
                    <li>STRONA WWW: www: pminwest.com</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer-contact-section">
              <h1 className="footer-contact-header">Skontaktuj się z nami</h1>
              <div className="footer-contact-form">
                <form
                  id="one-form"
                  className="form-contact"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <div className="form-field column">
                    <label className="footer-contact-label">Imię:</label>
                    <input
                      className="footer-contact-input"
                      name="user_name"
                      type="text"
                    />
                  </div>
                  <div className="form-field column">
                    <label className="footer-contact-label">
                      Adres e-mail:
                    </label>
                    <input
                      className="footer-contact-input"
                      name="user_email"
                      type="email"
                    />
                  </div>
                  <div className="form-field column">
                    <label className="footer-contact-label">
                      Numer telefonu(opcjonalnie)
                    </label>
                    <input
                      name="user_phone"
                      className="footer-contact-input"
                      type="text"
                    />
                  </div>
                  <div className="form-field column">
                    <label className="footer-contact-label">Wiadomość:</label>
                    <textarea
                      name="message"
                      className="footer-contact-input-message"
                    />
                  </div>
                  {/* <div className="footer-checkbox">
                    <div className="checkbox-container">
                      <input type="checkbox"></input>
                      Wyrażam zgodę na przesyłanie informacji handlowej (zobacz
                      więcej)...
                    </div>
                    <div className="checkbox-container">
                      <input type="checkbox"></input>
                      Wyrażam zgodę na przesyłanie newslettera(zobacz więcej)...
                    </div>
                    <div className="checkbox-container">
                      <input type="checkbox"></input>
                      Zapoznałam/em się z treścią Regulaminu i Polityki
                      prywatności(zobacz więcej)...
                    </div>
                    <div className="checkbox-container">
                      <input type="checkbox"></input>
                      Wyrażam zgodę na profilowanie (zobacz więcej)...
                    </div>
                  </div> */}
                  <div className="form-buttons">
                    <input
                      type="submit"
                      value="Wyślij"
                      className="footer-contact-submit"
                    />
                    <button
                      type="button"
                      onClick={onReset}
                      className="footer-contact-reset"
                    >
                      Wyczyść
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-icons"></div>
        </div>
      </div>
    </>
  );
};
export default Footer;
