import banner from "../assets/img/banner-1.jpg";
const Contact = () => {
  return (
    <div>
      <img src={banner} className="banner" alt="banner"></img>
      <div className="text-box">
        <h1>KONTAKT</h1>
        <ul className="contact">
          <li>PMINWEST SP. Z O.O</li>
          <li>SPIŻOWA 11A 70-723 SZCZECIN</li>
          <li>Nip: 9552559945</li>
          <li>606686818</li>
          <li>email:biuropminwest@wp.pl</li>
          <li>email: pminwest@wp.pl</li>
          <li>www: pminwest.com</li>
        </ul>
      </div>
    </div>
  );
};
export default Contact;
