import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Realization from "./components/Realization";
import Code from "./components/Code";
import Private from "./components/Private";

function App() {
  return (
    <>
      <Header></Header>
      <div className="App">
        <div className="main">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/realization" element={<Realization />} />
            <Route exact path="/code" element={<Code />} />
            <Route exact path="/private" element={<Private />} />
          </Routes>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
