import banner from "../assets/img/banner-1.jpg";
const About = () => {
  return (
    <div className="about-section">
      <img src={banner} className="banner" alt="banner"></img>
      <h1>KODEKS POSTĘPOWAŃ</h1>
      <div className="text-box">
        <p>
          Niniejszy kodeks jest zbiorem przyjętych, stosowanych przez spółkę
          zasad właściwego postępowania we wszelkich aspektach działalności
          firmy w warunkach gospodarki rynkowej, począwszy od istniejących
          uwarunkowań prawnych, poprzez relacje z pracownikami firmy,
        </p>
        <h3>
          <b>Etyka w biznesie</b>
        </h3>
        <p>
          Działalność gospodarcza, prowadzona w zgodzie z zasadami etyki i
          współżycia społecznego, jest gwarantem sukcesu danej organizacji w
          perspektywie długofalowej. Jesteśmy świadomi, że trwanie i rozwój
          biznesu są uzależnione od zaufania klientów i partnerów oraz
          przewidywalności i przejrzystości zachowani. Dlatego kładziemy
          szczególny nacisk na systematyczne i trwałe budowanie oraz wzmacnianie
          istniejących w naszej firmie standardów i schematów organizacyjnych, a
          także na włączanie przyjętych przez nas zasad etycznych do strategii
          naszego działania we wszystkich jej aspektach.
        </p>
        <p>
          Przywiązujemy szczególną uwagę do przestrzegania obowiązujących aktów
          prawnych i przepisów, terminowego wywiązywania się ze wszelkich
          zobowiązań, zarówno wobec organów administracji państwowej, klientów,
          podwykonawców, jak i pracowników, a także do księgowania wszelkich
          transakcji zgodnie z obowiązującymi Zasadami Rachunkowości oraz
          procedurami i przepisami wewnętrznymi. Kategorycznie sprzeciwiamy się
          wszelkim formom korupcji - nie wręczamy gratyfikacji pieniężnych,
          korzyści majątkowych ani przedmiotów materialnych innym podmiotom w
          celu skłonienia ich do działania niezgodnego z obowiązującymi
          przepisami prawa, jak również nie zabiegamy o podobne gratyfikacje ze
          strony innych. Stałość, wiarygodność i prawość w przyrzeczeniach i
          umowach stanowią dla nas klucz do osiągnięcia sukcesu w biznesie.
        </p>
        <h3>
          <b>Relacje z pracownikami</b>
        </h3>
        <p>
          Jedną z kluczowych wartości spółki PM-INWEST jest budowanie etycznych
          i partnerskich relacji z pracownikami. Zatrudnieni przez nas
          specjaliści to osoby posiadające najwyższe kwalifikacje, potrafiące
          połączyć swoje umiejętności z zaangażowaniem i pasją do pracy. To oni
          tworzą naszą firmę i to właśnie dzięki nim jesteśmy w stanie
          zaoferować usługi na najwyższym poziomie, w pełni spełniające
          oczekiwania naszych klientów. Dlatego dążymy do zapewnienia naszym
          pracownikom możliwości podnoszenia kwalifikacji oraz uczestniczenia w
          szkoleniach poszerzających zakres posiadanej przez nich wiedzy.
          Przywiązujemy szczególną wagę do zapewnienia bezpiecznych warunków
          pracy, zarówno zatrudnionym przez nas pracownikom jak i
          współpracującym z nami podmiotom. Zapewniamy równe traktowanie, bez
          względu na płeć, narodowość, wyznanie, rasę czy kolor skóry, aktywnie
          przeciwdziałając wszelkim formom dyskryminacji czy prześladowania w
          miejscu pracy. Warunki pracy w naszej firmie spełniają wszelkie wymogi
          prawodawstwa kraju, w którym świadczymy usługi.
        </p>
        <h3>
          <b>Klienci i ich potrzeby</b>
        </h3>
        <p>
          W warunkach gospodarki rynkowej klient dysponuje niekwestionowanym
          atutem - w jego rękach spoczywa możliwość dokonania wyboru towaru bądź
          usługi spośród niezliczonej rzeszy firm zabiegających o jego względy.
          Dlatego nasza polityka w zakresie jakości ukierunkowana jest na
          osiągnięcie doskonałości w zaspokajaniu uzasadnionych i słusznych
          potrzeb naszych klientów, co stanowi podstawę naszej firmy i dalszej
          jej egzystencji. Każdego klienta traktujemy z jednakową atencją.
          Potrafimy sprostać wysokim wymaganiom rynku budowlanego i jego
          uczestników dzięki partnerskim relacjom z naszymi kontrahentami oraz
          profesjonalizmowi i wysokim kwalifikacjom naszych pracowników.
          Przekonanie o tym, że zadowolenie naszych klientów stanowi miarę
          jakości naszej pracy oraz niezbędny warunek rozwoju firmy, skłania nas
          do ciągłej poprawy organizacji i metod naszej pracy, wdrażania
          nowoczesnych technologii oraz stymulowania rozwoju naszych pracowników
        </p>
        <h3>
          <b>Ochrona środowiska naturalnego</b>
        </h3>
        <p>
          Odpowiedzialność za środowisko naturalne, w ramach którego
          funkcjonujemy, jest jednym z fundamentów prowadzonej przez nas
          działalności. Wierzymy, że sposób, w jaki prowadzimy naszą
          działalność, może w znacznym stopniu przyczynić się do poprawy stanu
          środowiska naturalnego. Dlatego dążymy do jego ochrony poprzez
          systematyczne ograniczanie ilości wytwarzanych odpadów, ich
          ewidencjonowanie i segregację oraz kontrolę nad ich
          unieszkodliwianiem, a także wykorzystywanie technologii spełniających
          najwyższe wymagania w zakresie ochrony środowiska, optymalizację
          zużycia wody, energii elektrycznej oraz materiałów biurowych. Stawiamy
          sobie również za cel angażowanie naszych pracowników i
          współpracujących z nami podmiotów w działania proekologiczne, które
          mogą przyczynić się do sukcesywnego ograniczania negatywnego wpływu na
          środowisko naturalne. Jesteśmy świadomi, iż ekologiczne aspekty
          działalności budowlanej mają coraz większy wpływ na podejmowane przez
          inwestorów decyzje, dotyczące wyboru odpowiednich wykonawców. Dlatego
          aspekty proekologiczne stanowią jeden z wyznaczników naszej
          działalności, w ramach której dążymy do tego, aby oferowane przez nas
          usługi nie tylko spełniały najwyższe standardy jakościowe, ale również
          uwzględniały potrzeby i wymagania środowiska naturalnego, które nas
          otacza.
        </p>
        <h3>
          <b>Odpowiedzialność społeczna</b>
        </h3>
        <p>
          Firma odpowiedzialna społecznie potrafi w ramach prowadzonej
          działalności biznesowej uwzględnić i reagować na potrzeby otoczenia, w
          którym funkcjonuje. Dlatego też jednym z celów działalności spółki
          PM-INWEST jest budowanie partnerskich i etycznych relacji społecznych
          z różnymi podmiotami, w tym w szczególności z lokalną społecznością.
          Od wielu lat dzielimy się osiągniętym przez nas sukcesem w biznesie z
          innymi. Współfinansujemy szereg działań na rzecz osób najuboższych,
          chorych oraz zagrożonych wykluczeniem społecznym. Wspieramy również
          działalność edukacyjną i kulturalną Angażujemy się także w
          finansowanie projektów kościelnych. Co roku przeznaczamy na cele kultu
          religijnego i inne cele 5 % zysków.
        </p>
      </div>
    </div>
  );
};
export default About;
