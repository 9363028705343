import banner from "../assets/img/banner-1.jpg";
const About = () => {
  return (
    <div className="about-section">
      <img src={banner} className="banner" alt="banner"></img>
      <h1>FIRMA</h1>
      <div className="text-box">
        <p>
          <b>PM-INWEST SP.O.O.</b> działa jako generalny wykonawca we wszystkich
          segmentach budownictwa. Spółka wywodzi się z firmy PM – INWEST F.H.U
          powstałej i działającej w Szczecinie od 2009 r.
        </p>
        <p>
          Wizytówką przedsiębiorstwa jest ponad kilkunastoletnie doświadczenie
          oraz 40 zakończonych projektów inwestycyjnych należących w
          zdecydowanej większości do prestiżowych kontraktów budowalnych w
          zakresie budownictwa:
        </p>
        <ul className="about-list">
          {" "}
          <li>przemysłowego (budynki produkcyjne i magazynowe)</li>
          <li>
            użyteczności publicznej (hale sportowe, przedszkola, szkoły,
            szpitale, urzędy administracji publicznej, oraz obiekty biurowe i
            hotelowe)
          </li>
          <li>
            inżynierii drogowej (podjazdy, drogi wewnętrze, powiatowe, elementy
            miejskiej infrastruktury komunikacyjnej
          </li>
          <li>
            mieszkaniowego (budynki mieszkalne jednorodzinne, szeregowe oraz
            wielorodzinne).
          </li>
        </ul>
        <br></br>
        <p>
          <b>Firma</b> zatrudnia ponad 20 pracowników, wśród których znajduje
          się wysoko wykwalifikowana kadra inżynierska o różnorodnych
          specjalizacjach w branży budowlanej. Atutem firmy jest własny park
          maszynowy i profesjonalne wyszkolenie pracowników. Od 2021 roku
          PM-INWST Sp z o.o. sukcesywnie buduje stabilną firmę o
          zdywersyfikowanej działalności. PM-INWEST SP Z O.O.. gwarantuje
          najwyższą jakość usług, sprawną organizację, szybkie tempo prac w
          oparciu o najwyższe standardy oraz profesjonalną kadrę techniczną.
        </p>
      </div>
    </div>
  );
};
export default About;
