import r1 from "../assets/img/realizacje/1.jpg";
import r2 from "../assets/img/realizacje/2.jpg";
import r3 from "../assets/img/realizacje/3.jpg";
import r4 from "../assets/img/realizacje/4.jpg";
import r5 from "../assets/img/realizacje/5.jpeg";
import r6 from "../assets/img/realizacje/6.jpeg";
import r7 from "../assets/img/realizacje/7.jpeg";
import r8 from "../assets/img/realizacje/8.jpeg";
import r9 from "../assets/img/realizacje/9.jpeg";
import r10 from "../assets/img/realizacje/10.jpeg";
import r11 from "../assets/img/realizacje/11.jpg";
import r12 from "../assets/img/realizacje/12.jpg";
import r13 from "../assets/img/realizacje/13.jpeg";
import r14 from "../assets/img/realizacje/14.jpeg";
import r15 from "../assets/img/realizacje/15.jpeg";
import r16 from "../assets/img/realizacje/16.jpeg";
import r17 from "../assets/img/realizacje/17.jpeg";
import r18 from "../assets/img/realizacje/18.jpeg";
import r19 from "../assets/img/realizacje/19.jpeg";
import r20 from "../assets/img/realizacje/20.jpeg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Gallery = () => {
  return (
    <div>
      <div className="slide-container">
        <h3>Realizacje</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">1</div>
            <img className="image_slide" alt="r1" src={r1} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">2</div>
            <img className="image_slide" alt="r2" src={r2} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">3</div>
            <img className="image_slide" alt="r3" src={r3} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">4</div>
            <img className="image_slide" alt="r4" src={r4} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">5</div>
            <img className="image_slide" alt="r5" src={r5} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">6</div>
            <img className="image_slide" alt="r6" src={r6} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">7</div>
            <img className="image_slide" alt="r7" src={r7} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">8</div>
            <img className="image_slide" alt="r8" src={r8} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">9</div>
            <img className="image_slide" alt="r9" src={r9} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">10</div>
            <img className="image_slide" alt="r10" src={r10} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">11</div>
            <img className="image_slide" alt="r11" src={r11} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">12</div>
            <img className="image_slide" alt="r12" src={r12} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">13</div>
            <img className="image_slide" alt="r13" src={r13} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">14</div>
            <img className="image_slide" alt="r14" src={r14} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">15</div>
            <img className="image_slide" alt="r15" src={r15} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">16</div>
            <img className="image_slide" alt="r16" src={r16} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">17</div>
            <img className="image_slide" alt="r17" src={r17} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">18</div>
            <img className="image_slide" alt="r18" src={r18} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">19</div>
            <img className="image_slide" alt="r19" src={r19} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">20</div>
            <img className="image_slide" alt="r20" src={r20} />
          </div>
        </Fade>
      </div>
    </div>
  );
};
export default Gallery;
