import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const onMenu = () => {
    if (menu === false) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  };

  return (
    <header className="header">
      <div className="container header-content">
        <div className="logo">
          <Link to="/">
            <img
              width={100}
              className="logo-header"
              src={logo}
              alt="logo"
            ></img>
          </Link>
        </div>
        <i onClick={onMenu} className="fa fa-bars"></i>
        <ul className={menu === true ? "header-nav-visible" : "header-nav"}>
          <li>
            <Link className="header-link" to="/about">
              O NAS
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/contact">
              KONTAKT
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/code">
              KODEKS
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/private">
              POLITYKA PRYWATNOŚCI
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/gallery">
              ZREALIZOWANE PROJEKTY
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/realization">
              REALIZACJE
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};
export default Header;
