import banner_1 from "../assets/img/banner.jpg";
import banner_2 from "../assets/img/banner-1.jpg";
import banner_3 from "../assets/img/banner-2.jpg";
import photo_1 from "../assets/img/muzeum/Obraz1.jpg";
import photo_2 from "../assets/img/muzeum/Obraz2.jpg";
import photo_3 from "../assets/img/muzeum/Obraz3.jpg";
import photo_4 from "../assets/img/muzeum/Obraz4.jpg";
import photo_5 from "../assets/img/muzeum/Obraz5.jpg";
import photo_6 from "../assets/img/muzeum/Obraz6.jpg";
import photo_7 from "../assets/img/muzeum/Obraz7.jpg";
import szkoła_1 from "../assets/img/szkoła/szkoła1.jpg";
import szkoła_2 from "../assets/img/szkoła/szkoła2.jpg";
import szkoła_3 from "../assets/img/szkoła/szkoła3.jpg";
import szkoła_4 from "../assets/img/szkoła/szkoła4.jpg";
import szkoła_5 from "../assets/img/szkoła/szkoła5.jpg";
import szkoła_6 from "../assets/img/szkoła/szkoła6.jpg";
import skansen_1 from "../assets/img/skansen/skansen.jpg";
import skansen_2 from "../assets/img/skansen/skansen 1.jpg";
import skansen_3 from "../assets/img/skansen/skansen 2.jpg";
import skansen_5 from "../assets/img/skansen/skansen 4.jpg";
import skansen_6 from "../assets/img/skansen/skans.jpg";
import przychodnia_1 from "../assets/img/przychodnia/0001.jpg";
import przychodnia_2 from "../assets/img/przychodnia/0002.jpg";
import przychodnia_3 from "../assets/img/przychodnia/0003.jpg";
import przychodnia_5 from "../assets/img/przychodnia/0005.jpg";
import przychodnia_7 from "../assets/img/przychodnia/0007.jpg";
import przychodnia_8 from "../assets/img/przychodnia/0008.jpg";
import przychodnia_9 from "../assets/img/przychodnia/0009.jpg";
import przychodnia_10 from "../assets/img/przychodnia/0010.jpg";
import przychodnia_12 from "../assets/img/przychodnia/0012.jpg";
import przychodnia_13 from "../assets/img/przychodnia/0013.jpg";
import przychodnia_14 from "../assets/img/przychodnia/0015.jpg";
import przychodnia_16 from "../assets/img/przychodnia/0016.jpg";
import przychodnia_17 from "../assets/img/przychodnia/0017.jpg";
import przychodnia_18 from "../assets/img/przychodnia/0018.jpg";
import przychodnia_19 from "../assets/img/przychodnia/0019.jpg";
import przychodnia_20 from "../assets/img/przychodnia/0020.jpg";
import przychodnia_21 from "../assets/img/przychodnia/0021.jpg";
import przychodnia_22 from "../assets/img/przychodnia/0022.jpg";
import przychodnia_23 from "../assets/img/przychodnia/0023.jpg";
import nr9_1 from "../assets/img/przedszkole nr 9/nr9_1.jpg";
import nr9_2 from "../assets/img/przedszkole nr 9/nr9_2.jpg";
import nr9_3 from "../assets/img/przedszkole nr 9/nr9_3.jpg";
import nr9_4 from "../assets/img/przedszkole nr 9/nr9_4.jpg";
import nr9_5 from "../assets/img/przedszkole nr 9/nr9_5.jpg";
import nr9_6 from "../assets/img/przedszkole nr 9/nr9_6.jpg";
import nr9_7 from "../assets/img/przedszkole nr 9/nr9_7.jpg";
import nr9_8 from "../assets/img/przedszkole nr 9/nr9_8.jpg";
import nr14_1 from "../assets/img/przedszkole nr 14/nr14_1.jpg";
import nr14_2 from "../assets/img/przedszkole nr 14/nr14_2.jpg";
import nr14_3 from "../assets/img/przedszkole nr 14/nr14_3.jpg";
import nr14_4 from "../assets/img/przedszkole nr 14/nr14_4.jpg";
import nr14_5 from "../assets/img/przedszkole nr 14/nr14_5.jpg";
import nr14_6 from "../assets/img/przedszkole nr 14/nr14_6.jpg";
import nr14_7 from "../assets/img/przedszkole nr 14/nr14_7.jpg";
import stara_rzeźnia_1 from "../assets/img/stara rzeźnia/budynek.jpg";
import stara_rzeźnia_2 from "../assets/img/stara rzeźnia/elewacja.jpg";
import stara_rzeźnia_3 from "../assets/img/stara rzeźnia/restauracja.jpg";
import stara_rzeźnia_4 from "../assets/img/stara rzeźnia/róża_wiatrów.jpg";
import stara_rzeźnia_5 from "../assets/img/stara rzeźnia/sala_konferencyjna.png";
import stara_rzeźnia_6 from "../assets/img/stara rzeźnia/stara_rzeźnia.png";
import kompleks1 from "../assets/img/kompleks1.jpg";
import kompleks2 from "../assets/img/kompleks2.jpg";
import kompleks3 from "../assets/img/kompleks3.jpg";
import PP9 from "../assets/img/Termomodernizacja przedszkola PP9.jpg";
import PP14 from "../assets/img/Termomodernizacja przedszkola PP14.jpg";
import build from "../assets/img/Budowa domu pomocy społecznej kolsk.jpg";
import build_2 from "../assets/img/Budowa domu pomocy społecznej kolsk 2.jpg";
import build_3 from "../assets/img/Budowa domu pomocy społecznej kolsk 3.jpg";
import DPS from "../assets/img/PRZEBUDOWA WĘZŁA DPS.jpg";
import DPS_2 from "../assets/img/PRZEBUDOWA WĘZŁA DPS 2.jpg";
import sad_1 from "../assets/img/sąd.jpg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Gallery = () => {
  console.log("sad",sad_1)
  return (
    <div>
      {/* <img src={banner} className="banner" alt="banner"></img> */}
      <div className="slide-container">
      <h3>
      BUDYNEK PRZYCHODNI 2024
        </h3>
        <Fade>
        <div className="each-fade">
          <div className="each-fade-title">Widok z zewnątrz</div>
            <img className="image_slide" alt="przychodnia_22" src={przychodnia_22} />
          </div>
          <div className="each-fade">
          <div className="each-fade-title">Widok z zewnątrz</div>
            <img className="image_slide" alt="przychodnia_23" src={przychodnia_23} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_1" src={przychodnia_1} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_2" src={przychodnia_2} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_3" src={przychodnia_3} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_4" src={przychodnia_5} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_5" src={przychodnia_7} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_7" src={przychodnia_8} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_8" src={przychodnia_9}/>
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_9" src={przychodnia_10} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_12" src={przychodnia_12} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_13" src={przychodnia_13} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_14" src={przychodnia_14} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_16" src={przychodnia_16} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_17" src={przychodnia_17} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_18" src={przychodnia_18}/>
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_19" src={przychodnia_19} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_20" src={przychodnia_20} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="przychodnia_21" src={przychodnia_21} />
          </div>
        </Fade>
        <h3>
        SKANSEN BUDOWA 2023 
        </h3>
        <Fade>
        <div className="each-fade">
          <div className="each-fade-title">Widok z zewnątrz</div>
            <img className="image_slide" alt="skans" src={skansen_6} />
          </div>
          <div className="each-fade">
          <div className="each-fade-title">Widok z zewnątrz</div>
            <img className="image_slide" alt="skansen" src={skansen_1} />
          </div>
          <div className="each-fade">
          <div className="each-fade-title">Widok z zewnątrz</div>
            <img className="image_slide" alt="skansen_3" src={skansen_2} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="skansen_1" src={skansen_5} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="skansen_2" src={skansen_3} />
          </div>
        </Fade>
        <h3>APARTAMENT</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">Widoki z apartamentu</div>
            <img className="image_slide" alt="banner_1" src={banner_1} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Widoki z apartamentu</div>
            <img className="image_slide" alt="banner_2" src={banner_2} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Widoki z apartamentu</div>
            <img className="image_slide" alt="banner_3" src={banner_3} />
          </div>
        </Fade>
        <h3>MODERNIZACJA PRZEDSZKOLA PUBLICZNEGO NR 9 W SZCZECINIE</h3>
        <Fade>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr9-1" src={nr9_1} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-2" src={nr9_2} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-3" src={nr9_3} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-4" src={nr9_4} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-5" src={nr9_5} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-6" src={nr9_6} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-7" src={nr9_7} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="nr9-8" src={nr9_8} />
          </div>
        </Fade>
        <h3>MODERNIZACJA PRZEDSZKOLA PUBLICZNEGO NR 14 W SZCZECINIE</h3>
        <Fade>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-1" src={nr14_1} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-2" src={nr14_2} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-3" src={nr14_3} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-4" src={nr14_4} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-5" src={nr14_5} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-6" src={nr14_6} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="nr14-7" src={nr14_7} />
          </div>
        </Fade>
        <h3>STARA RZEŹNIA</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">Budynek Starej Rzeźni</div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_1"
              src={stara_rzeźnia_1}
            />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Elewacja w Rzeźni</div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_2"
              src={stara_rzeźnia_2}
            />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Restauracja w Starej Rzeźni</div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_3"
              src={stara_rzeźnia_3}
            />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Róża Wiatrów w Starej Rzeźni</div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_3"
              src={stara_rzeźnia_4}
            />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">
              Sala Konferencyjna w Starej Rzeźni
            </div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_3"
              src={stara_rzeźnia_5}
            />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">Stara Rzeźnia</div>
            <img
              className="image_slide"
              alt="stara_rzeżnia_3"
              src={stara_rzeźnia_6}
            />
          </div>
        </Fade>
        {/* <h3>Nowe elewacje</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">1</div>
            <img className="image_slide" alt="image_1" src={image} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">2</div>
            <img className="image_slide" alt="image_2" src={image_2} />
          </div>
          <div className="each-fade">
            <div className="each-fade-title">3</div>
            <img className="image_slide" alt="image_3" src={image_3} />
          </div>
        </Fade> */}
        <h3>TERMOMODERNIZACJA OBIEKTU PP9</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">
              TERMOMODERNIZACJA PRZEDSZKOLA PP9
            </div>
            <img className="image_slide" alt="PP9" src={PP9} />
          </div>
        </Fade>
        <h3>TERMOMODERNIZACJA OBIEKTU PP14</h3>
        <Fade>
          <div className="each-fade">
            <div className="each-fade-title">
              TERMOMODERNIZACJA PRZEDSZKOLA PP14
            </div>
            <img className="image_slide" alt="PP14" src={PP14} />
          </div>
        </Fade>
        <h3>BUDOWA DOMU POMOCY SPOŁECZNEJ KOLSK</h3>
        <Fade>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="build" src={build} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="build_2" src={build_2} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">3</div> */}
            <img className="image_slide" alt="build_3" src={build_3} />
          </div>
        </Fade>
        <h3>PRZEBUDOWA WĘZŁA DPS</h3>
        <Fade>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="DPS" src={DPS} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="DPS_2" src={DPS_2} />
          </div>
        </Fade>
        <h3>BUDOWA KOMPLEKSU BUDYNKÓW DLA AGENCJI ROLNEJ NOWE LNIE</h3>
        <Fade>
          <div className="each-fade">
            {/* <div className="each-fade-title">1</div> */}
            <img className="image_slide" alt="kompleks1" src={kompleks1} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="kompleks2" src={kompleks2} />
          </div>
          <div className="each-fade">
            {/* <div className="each-fade-title">2</div> */}
            <img className="image_slide" alt="kompleks3" src={kompleks3} />
          </div>
        </Fade>
        <h3>
          MUZEUM TECHNIKI I KOMUNIKACJI 'ZAJEZDNIA SZTUKI' W
          SZCZECINIE(PODWYKONAWCA){" "}
        </h3>
        <Fade>
          <div className="each-fade">
            <img className="image_slide" alt="photo_1" src={photo_1} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_2" src={photo_2} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_3" src={photo_3} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_4" src={photo_4} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_5" src={photo_5} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_6" src={photo_6} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="photo_7" src={photo_7} />
          </div>
        </Fade>
        <h3>PODSTAWOWA SZKOŁA W DOBREJ(PODWYKONAWCA)</h3>
        <Fade>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_2" src={szkoła_2} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_1" src={szkoła_1} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_3" src={szkoła_3} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_4" src={szkoła_4} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_5" src={szkoła_5} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="szkoła_6" src={szkoła_6} />
          </div>
        </Fade>
        <h3>SĄD W STARGARDZIE(PODWYKONAWCA)</h3>
        <Fade>
          <div className="each-fade">
            <img className="image_slide" alt="sad_1" src={sad_1} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="sad_2" src={sad_1} />
          </div>
          <div className="each-fade">
            <img className="image_slide" alt="sad_3" src={sad_1} />
          </div>
        </Fade>
      </div>
    </div>
  );
};
export default Gallery;
